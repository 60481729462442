import axios from "axios";
import { toast } from "react-toastify";

const BASE_URL = process.env.REACT_APP_BASE_URL;

export const GET_COURSE_REQUEST = "GET_COURSE_REQUEST";
export const GET_COURSE_SUCCESS = "GET_COURSE_SUCCESS";
export const GET_COURSE_FAILURE = "GET_COURSE_FAILURE";

export const GET_COURSE_DETAILS_REQUEST = "GET_COURSE_DETAILS_REQUEST";
export const GET_COURSE_DETAILS_SUCCESS = "GET_COURSE_DETAILS_SUCCESS";
export const GET_COURSE_DETAILS_FAILURE = "GET_COURSE_DETAILS_FAILURE";

export const getCourses = () => {
  return async (dispatch) => {
    try {
      dispatch({ type: GET_COURSE_REQUEST });
      const url = `${BASE_URL}/course/list/v1`;
      const response = await axios.get(url, {
        headers: {
          Authorization: localStorage.getItem("token"),
        },
      });
      dispatch({ type: GET_COURSE_SUCCESS, payload: response.data });
    } catch (error) {
      dispatch({ type: GET_COURSE_FAILURE, payload: error.message });
      toast.error(error?.response?.data?.message, {
        position: "top-right",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      });
    }
  };
};

export const getCoursesDetails = (id) => {
  return async (dispatch) => {
    try {
      dispatch({ type: GET_COURSE_DETAILS_REQUEST });
      const url = `${BASE_URL}/course/details/${id}/v1`;
      const response = await axios.get(url, {
        headers: {
          Authorization: localStorage.getItem("token"),
        },
      });
      dispatch({ type: GET_COURSE_DETAILS_SUCCESS, payload: response.data });
    } catch (error) {
      dispatch({ type: GET_COURSE_DETAILS_FAILURE, payload: error.message });
      toast.error(error?.response?.data?.message, {
        position: "top-right",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      });
    }
  };
};
