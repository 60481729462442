import React, { useState } from "react";
import { Col, Container, Row } from "react-bootstrap";
import * as userAction from "../../redux/user/userAction";
import { useDispatch } from "react-redux";
import { toast } from "react-toastify";
import { useNavigate, useParams } from "react-router-dom";
import brand_logo from "../../assets/svg/brand_logo.svg";

const ResetPassword = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const {resettoken}=useParams()
  const [resetForm, setResetForm] = useState({
    newPassword: "",
    confirmPassword: "",
  });

  const handleChange = (e) => {
    const { name, value } = e.target;
    setResetForm({
      ...resetForm,
      [name]: value,
    });
  };

  const handleResetButtonClick = (e) => {
    e.preventDefault();

    if (resetForm.newPassword !== resetForm.confirmPassword) {
      toast.error("Password does not matched");
    }
    dispatch(
      userAction.resetPassword({ password: resetForm.newPassword },resettoken, navigate)
    );
  };
  return (
    <section className="login_signup_page">
      <Container className="h-100">
        <Row>
          <Col lg={12} md={12}>
            <span className="logo_light">
            <img onClick={() => navigate('/')} src={brand_logo} alt="brand-logo" />
            </span>
          </Col>
        </Row>
        <Row className="h-100">
          <Col
            xl={5}
            lg={6}
            md={9}
            sm={12}
            className="mx-auto login_signup_content_left"
          >
            <h1>Welcome to AceMe6</h1>
            <p>
              Experience AceMed's Success Symphony! Accelerate your journey with
              our Guaranteed Notes and Flashcards—excel in style!
            </p>
          </Col>
          <Col
            xl={5}
            lg={6}
            md={9}
            sm={12}
            className="mx-auto login_signup_content_right"
          >
            <h3>Let’s get started</h3>

            <p style={{ color: "#990d0e" }}>Reset Password</p>

            <>
              <form onSubmit={(e) => handleResetButtonClick(e)}>
                <div className="form-group my-3">
                  <label className="mb-2">New Password</label>
                  <input
                    value={resetForm.newPassword}
                    type="password"
                    name="newPassword"
                    className="form-control"
                    id="profile_phone"
                    placeholder="Enter Your Password"
                    onChange={(e) => handleChange(e)}
                    required
                  />
                </div>
                <div className="form-group mt-3 mb-5">
                  <label className="mb-2">Confirm Password</label>
                  <input
                    type="password"
                    value={resetForm.confirmPassword}
                    name="confirmPassword"
                    className="form-control"
                    id="profile_password"
                    placeholder="Enter Your Confirm Password"
                    onChange={(e) => handleChange(e)}
                    required
                  />
                </div>

                <button
                  className="btn_primary w-100 mt-4"
                  style={{ cursor: "pointer" }}
                  type="submit"
                >
                  Reset Password
                </button>
              </form>
            </>
          </Col>
        </Row>
      </Container>
    </section>
  );
};

export default ResetPassword;
