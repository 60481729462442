import * as planAction from "./planAction";

export const planFeatureKey = "planInfo";

const initialState = {
  loading: false,
  errorMsg: "",
  plans: [],
};

export const reducer = (state = initialState, action) => {
  const { type, payload } = action;
  switch (type) {
    case planAction.GET_PLAN_DETAILS_REQUEST:
      return {
        ...state,
        loading: true,
      };
    case planAction.GET_PLAN_DETAILS_SUCCESS:
      return {
        ...state,
        loading: false,
        plans: payload.data,
      };
    case planAction.GET_PLAN_DETAILS_FAILURE:
      return {
        ...state,
        loading: false,
        errorMsg: payload,
      };

    case planAction.MAKE_PAYMENT_REQUEST:
      return {
        ...state,
        loading: true,
      };
    case planAction.MAKE_PAYMENT_SUCCESS:
      return {
        ...state,
        loading: false,
      };
    case planAction.MAKE_PAYMENT_FAILURE:
      return {
        ...state,
        loading: false,
        errorMsg: payload,
      };

    case planAction.SET_PLAN_EXPIRY_REQUEST:
      return {
        ...state,
        loading: true,
      };
    case planAction.SET_PLAN_EXPIRY_SUCCESS:
      return {
        ...state,
        loading: false,
      };
    case planAction.SET_PLAN_EXPIRY_FAILURE:
      return {
        ...state,
        loading: false,
        errorMsg: payload,
      };
    default:
      return state;
  }
};
