import * as homeAction from "./homeAction";

export const homeFeatureKey = "homeInfo";

const initialState = {
  loading: false,
  errorMsg: "",
  homepage: [],
  homepageDetails: {},
  isSubjectUpdated: false,
};

export const reducer = (state = initialState, action) => {
  const { type, payload } = action;
  switch (type) {
    case homeAction.GET_HOMEPAGE_PRODUCTS_REQUEST:
      return {
        ...state,
        loading: true,
      };
    case homeAction.GET_HOMEPAGE_PRODUCTS_SUCCESS:
      return {
        ...state,
        loading: false,
        homepage: payload.data,
      };
    case homeAction.GET_HOMEPAGE_PRODUCTS_FAILURE:
      return {
        ...state,
        loading: false,
        errorMsg: payload,
      };
    case homeAction.ASSIGN_SUBJECT_TO_THE_USER_REQUEST:
      return {
        ...state,
        loading: true,
      };
    case homeAction.ASSIGN_SUBJECT_TO_THE_USER_SUCCESS:
      return {
        ...state,
        loading: false,
        isSubjectUpdated: true,
      };
    case homeAction.ASSIGN_SUBJECT_TO_THE_USER_FAILURE:
      return {
        ...state,
        loading: false,
        errorMsg: payload,
      };
    case "IS_SUBJECT_ASSIGNED":
      return {
        ...state,
        isSubjectUpdated: false,
      };
    default:
      return state;
  }
};
