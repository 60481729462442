import React, { useEffect } from "react";
import { Col, Container, Row } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import { getCourses, getCoursesDetails } from "../redux/courses/courseAction";
import { useNavigate, useParams } from "react-router-dom";

const BASE_AWS_URL = process.env.REACT_APP_AWS_URL;

const UniversityDetail = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { courses, coursesDetails } = useSelector((state) => state.coursesInfo);
  const { id } = useParams();

  useEffect(() => {
    dispatch(getCourses());
  }, []);

  useEffect(() => {
    if (id) {
      dispatch(getCoursesDetails(id));
    }
  }, [dispatch, id]);

  const filteredCourses = courses.filter(course => course._id !== id);

  return (
    <>
      <section className="university-detail-section">
        <Container>
          <Row className="px-1 px-md-5 flex-column-reverse flex-lg-row">
            <Col lg={6} md={12} sm={12}>
              <div className="university-detail">
                <h6>the home of online {coursesDetails?.courseName}</h6>
                <h2>
                  The Premium {coursesDetails?.courseName} Package
                  <span>{coursesDetails?.duration}</span>
                </h2>
                <p>{coursesDetails?.shortDescription}</p>
                {/* <p>
                  This package is for H1-H2 Hopefuls in LC Maths looking for
                  Face- to-Face weekly grinds in Bandon. 
                </p> */}
                <p>{coursesDetails?.longDescription}</p>
                <button className="btn_primary w-100 mt-5">Buy now</button>
              </div>
            </Col>
            <Col lg={6} md={12} sm={12}>
              <img
                className="university-detail-img"
                src={`${BASE_AWS_URL}${coursesDetails?.image}`}
                alt="course image"
              />
            </Col>
          </Row>
        </Container>
      </section>
      <section className="university-detail-courses-section">
        <Container>
          <Col>
            <h3 className="mb-5">Courses</h3>
            {courses.length > 0 && (
              <Row className="gx-4 gy-5">
                {filteredCourses.map((course, index) => (
                  <Col xxl={4} xl={4} lg={4} md={6} sm={12} key={index}>
                    <div
                      className="university-course-card"
                      onClick={() => navigate(`/courses/${course._id}`)}
                      style={{ cursor: "pointer" }}
                    >
                      <div>
                        <div>
                          <span className="tag-primary">
                            Leaving Certificate
                          </span>
                          <br />
                          <span className="tag-dark">Higher Level</span>
                        </div>
                        <img
                          src={`${BASE_AWS_URL}${course.image}`}
                          alt="Course Thumbnail"
                        />
                      </div>
                      <h3>Weekly Face-to-Face Grinds {course.duration}</h3>
                      <p>{course.shortDescription}</p>
                    </div>
                  </Col>
                ))}
              </Row>
            )}
          </Col>
        </Container>
      </section>
    </>
  );
};

export default UniversityDetail;
