import * as topicAction from "./topicAction";

export const topicFeatureKey = "topicInfo";

const initialState = {
  loading: false,
  errorMsg: "",
  topics: [],
  topicDetails: {},
  progressCount: [],
  isTopicUpdateStatus: false,
  completionStatus: false,
  rating: 0,
};

export const reducer = (state = initialState, action) => {
  const { type, payload } = action;
  switch (type) {
    // get topic list

    case topicAction.GET_TOPICS_LIST_REQUEST:
      return {
        ...state,
        loading: true,
      };
    case topicAction.GET_TOPICS_LIST_SUCCESS:
      return {
        ...state,
        loading: false,
        topics: payload.data,
      };
    case topicAction.GET_TOPICS_LIST_FAILURE:
      return {
        ...state,
        loading: false,
        errorMsg: payload,
        topics: [],
      };
    // get progress
    case topicAction.GET_TOPICS_PROGRESS_REQUEST:
      return {
        ...state,
        loading: true,
      };
    case topicAction.GET_TOPICS_PROGRESS_SUCCESS:
      return {
        ...state,
        loading: false,
        progressCount: payload.data,
      };
    case topicAction.GET_TOPICS_PROGRESS_FAILURE:
      return {
        ...state,
        loading: false,
        errorMsg: payload,
        progressCount: [],
      };
    case "IS_TOPIC_UPDATE":
      return {
        ...state,
        isTopicUpdateStatus: false,
      };
    case topicAction.UPDATE_TOPICS_PROGRESS_REQUEST:
      return {
        ...state,
        loading: true,
      };
    case topicAction.UPDATE_TOPICS_PROGRESS_SUCCESS:
      return {
        ...state,
        loading: false,
        isTopicUpdateStatus: true,
      };
    case topicAction.UPDATE_TOPICS_PROGRESS_FAILURE:
      return {
        ...state,
        loading: false,
        errorMsg: payload,
      };
    // assign topic to the user
    case topicAction.ASSIGN_TOPICS_TO_THE_USER_REQUEST:
      return {
        ...state,
        loading: true,
      };
    case topicAction.ASSIGN_TOPICS_TO_THE_USER_SUCCESS:
      return {
        ...state,
        loading: false,
        isTopicUpdateStatus: true,
      };
    case topicAction.ASSIGN_TOPICS_TO_THE_USER_FAILURE:
      return {
        ...state,
        loading: false,
        errorMsg: payload,
      };

    // update ratings

    case topicAction.UPDATE_RATING_TO_TOPIC_REQUEST:
      return {
        ...state,
        loading: true,
      };
    case topicAction.UPDATE_RATING_TO_TOPIC_SUCCESS:
      return {
        ...state,
        loading: false,
        isTopicUpdateStatus: true,
        rating: payload.data,
      };
    case topicAction.UPDATE_RATING_TO_TOPIC_FAILURE:
      return {
        ...state,
        loading: false,
        errorMsg: payload,
      };

    // mark subject completed

    case topicAction.MARK_TOPIC_COMPLETED_REQUEST:
      return {
        ...state,
        loading: true,
      };
    case topicAction.MARK_TOPIC_COMPLETED_SUCCESS:
      return {
        ...state,
        loading: false,
        isTopicUpdateStatus: true,
      };
    case topicAction.MARK_TOPIC_COMPLETED_FAILURE:
      return {
        ...state,
        loading: false,
        errorMsg: payload,
      };

    case topicAction.MARK_TOPIC_INCOMPLETED_REQUEST:
      return {
        ...state,
        loading: true,
      };
    case topicAction.MARK_TOPIC_INCOMPLETED_SUCCESS:
      return {
        ...state,
        loading: false,
        isTopicUpdateStatus: true,
      };
    case topicAction.MARK_TOPIC_INCOMPLETED_FAILURE:
      return {
        ...state,
        loading: false,
        errorMsg: payload,
      };
    case topicAction.GET_MARK_TOPIC_STATUS_REQUEST:
      return {
        ...state,
        loading: true,
      };
    case topicAction.GET_MARK_TOPIC_STATUS_SUCCESS:
      return {
        ...state,
        loading: false,
        completionStatus: payload.data,
      };
    case topicAction.GET_MARK_TOPIC_STATUS_FAILURE:
      return {
        ...state,
        loading: false,
        errorMsg: payload,
      };
    default:
      return state;
  }
};
