import axios from "axios";
import { toast } from "react-toastify";

const BASE_URL = process.env.REACT_APP_BASE_URL;

export const GET_SUBJECTS_REQUEST = "GET_SUBJECTS_REQUEST";
export const GET_SUBJECTS_SUCCESS = "GET_SUBJECTS_SUCCESS";
export const GET_SUBJECTS_FAILURE = "GET_SUBJECTS_FAILURE";

export const GET_OVERALL_PROGRESS_FAILURE = "GET_OVERALL_PROGRESS_FAILURE";
export const GET_OVERALL_PROGRESS_REQUEST = "GET_OVERALL_PROGRESS_REQUEST";
export const GET_OVERALL_PROGRESS_SUCCESS = "GET_OVERALL_PROGRESS_SUCCESS";

export const COUNT_SUBJECT_OVERALL_PROGRESS_REQUEST =
  "COUNT_SUBJECT_OVERALL_PROGRESS_REQUEST";
export const COUNT_SUBJECT_OVERALL_PROGRESS_SUCCESS =
  "COUNT_SUBJECT_OVERALL_PROGRESS_SUCCESS";
export const COUNT_SUBJECT_OVERALL_PROGRESS_FAILURE =
  "COUNT_SUBJECT_OVERALL_PROGRESS_FAILURE";

export const GET_MY_COMPLETED_SUBJECTS_REQUEST =
  "GET_MY_COMPLETED_SUBJECTS_REQUEST";
export const GET_MY_COMPLETED_SUBJECTS_SUCCESS =
  "GET_MY_COMPLETED_SUBJECTS_SUCCESS";
export const GET_MY_COMPLETED_SUBJECTS_FAILURE =
  "GET_MY_COMPLETED_SUBJECTS_FAILURE";

export const getSubjects = (setIsLoading) => {
  return async (dispatch) => {
    try {
      dispatch({ type: GET_SUBJECTS_REQUEST });
      const url = `${BASE_URL}/subject/get/subject/list/v1`;
      const response = await axios.get(url, {
        headers: {
          Authorization: localStorage.getItem("token"),
        },
      });
      setIsLoading(false);
      dispatch({ type: GET_SUBJECTS_SUCCESS, payload: response.data });
    } catch (error) {
      console.error(error);
      if (
        error?.response?.data?.status == 401 ||
        error?.response?.data?.status == 403
      ) {
        window.location.href = "/login";
      }
      dispatch({ type: GET_SUBJECTS_FAILURE, payload: error.message });
      toast.error(error?.response?.data?.message, {
        position: "top-right",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      });
    }
  };
};

export const countSubjectOverallProgress = (subjectId) => {
  return async (dispatch) => {
    try {
      dispatch({ type: COUNT_SUBJECT_OVERALL_PROGRESS_REQUEST });
      const url = `${BASE_URL}/user/subject/count/overall/progress/${subjectId}/v1`;
      const response = await axios.post(
        url,
        {},
        {
          headers: {
            Authorization: localStorage.getItem("token"),
          },
        }
      );
      dispatch({
        type: COUNT_SUBJECT_OVERALL_PROGRESS_SUCCESS,
        payload: response.data,
      });
    } catch (error) {
      if (
        error?.response?.data?.status == 401 ||
        error?.response?.data?.status == 403
      ) {
        window.location.href = "/login";
      }
      dispatch({
        type: COUNT_SUBJECT_OVERALL_PROGRESS_FAILURE,
        payload: error.message,
      });
    }
  };
};

export const getAllMyCompletedSubjects = () => {
  return async (dispatch) => {
    try {
      dispatch({ type: GET_MY_COMPLETED_SUBJECTS_REQUEST });
      const url = `${BASE_URL}/user/subject/get/completed/subjects/v1`;
      const response = await axios.get(url, {
        headers: {
          Authorization: localStorage.getItem("token"),
        },
      });
      dispatch({
        type: GET_MY_COMPLETED_SUBJECTS_SUCCESS,
        payload: response.data,
      });
    } catch (error) {
      dispatch({
        type: GET_MY_COMPLETED_SUBJECTS_FAILURE,
        payload: error.message,
      });
    }
  };
};

export const getOverAllProgress = (subjectId) => {
  return async (dispatch) => {
    try {
      dispatch({ type: GET_OVERALL_PROGRESS_REQUEST });
      const url = `${BASE_URL}/user/subject/get/progress/${subjectId}/v1`;
      const response = await axios.get(url, {
        headers: {
          Authorization: localStorage.getItem("token"),
        },
      });
      dispatch({ type: GET_OVERALL_PROGRESS_SUCCESS, payload: response.data });
    } catch (error) {
      dispatch({ type: GET_OVERALL_PROGRESS_FAILURE, payload: error.message });
    }
  };
};
