import axios from "axios";
import { toast } from "react-toastify";

const BASE_URL = process.env.REACT_APP_BASE_URL;

// for notes

export const GET_TOPIC_NOTES_REQUEST = "GET_TOPIC_NOTES_REQUEST";
export const GET_TOPIC_NOTES_SUCCESS = "GET_TOPIC_NOTES_SUCCESS";
export const GET_TOPIC_NOTES_FAILURE = "GET_TOPIC_NOTES_FAILURE";

export const UPDATE_READ_STATUS_REQUEST = "UPDATE_READ_STATUS_REQUEST";
export const UPDATE_READ_STATUS_SUCCESS = "UPDATE_READ_STATUS_SUCCESS";
export const UPDATE_READ_STATUS_FAILURE = "UPDATE_READ_STATUS_FAILURE";

// for flash cards

export const GET_FLASH_CARD_REQUEST = "GET_FLASH_CARD_REQUEST";
export const GET_FLASH_CARD_SUCCESS = "GET_FLASH_CARD_SUCCESS";
export const GET_FLASH_CARD_FAILURE = "GET_FLASH_CARD_FAILURE";

// for mcq cards
export const GET_MCQ_CARD_REQUEST = "GET_MCQ_CARD_REQUEST";
export const GET_MCQ_CARD_SUCCESS = "GET_MCQ_CARD_SUCCESS";
export const GET_MCQ_CARD_FAILURE = "GET_MCQ_CARD_FAILURE";

export const UPDATE_OPEN_STATUS_CARDS_REQUEST =
  "UPDATE_OPEN_STATUS_CARDS_REQUEST";
export const UPDATE_OPEN_STATUS_CARDS_SUCCESS =
  "UPDATE_OPEN_STATUS_CARDS_SUCCESS";
export const UPDATE_OPEN_STATUS_CARDS_FAILURE =
  "UPDATE_OPEN_STATUS_CARDS_FAILURE";

export const UPDATE_FLASH_CARDS_PROGRESS_REQUEST =
  "UPDATE_FLASH_CARDS_PROGRESS_REQUEST";
export const UPDATE_FLASH_CARDS_PROGRESS_SUCCESS =
  "UPDATE_FLASH_CARDS_PROGRESS_SUCCESS";
export const UPDATE_FLASH_CARDS_PROGRESS_FAILURE =
  "UPDATE_FLASH_CARDS_PROGRESS_FAILURE";

// for videos

export const GET_VIDEOS_REQUEST = "GET_VIDEOS_REQUEST";
export const GET_VIDEOS_SUCCESS = "GET_VIDEOS_SUCCESS";
export const GET_VIDEOS_FAILURE = "GET_VIDEOS_FAILURE";

export const UPDATE_COMPLETED_VIDEO_STATUS_REQUEST =
  "UPDATE_COMPLETED_VIDEO_STATUS_REQUEST";
export const UPDATE_COMPLETED_VIDEO_STATUS_SUCCESS =
  "UPDATE_COMPLETED_VIDEO_STATUS_SUCCESS";
export const UPDATE_COMPLETED_VIDEO_STATUS_FAILURE =
  "UPDATE_COMPLETED_VIDEO_STATUS_FAILURE";

// get notes

export const getTopicNotes = (topicId, subjectId, setIsLoading) => {
  return async (dispatch) => {
    try {
      dispatch({ type: "GET_TOPIC_NOTES_REQUEST" });
      setIsLoading(true);
      const url = `${BASE_URL}/user/subject/get/assigned/notes/${topicId}/${subjectId}/v1`;
      const response = await axios.get(url, {
        headers: {
          Authorization: localStorage.getItem("token"),
        },
      });
      dispatch({ type: "GET_TOPIC_NOTES_SUCCESS", payload: response.data });
    } catch (error) {
      console.error(error);
      dispatch({ type: "GET_TOPIC_NOTES_FAILURE", payload: error.message });
    } finally {
      setIsLoading(false);
    }
  };
};

// updating read status

export const updateReadStatusForPdfNotes = (topicId, pdfId, assignedId) => {
  return async (dispatch) => {
    try {
      dispatch({ type: "IS_TOPIC_UPDATE" });
      dispatch({ type: UPDATE_READ_STATUS_REQUEST });
      const url = `${BASE_URL}/user/subject/update/read/status/${pdfId}/v1 `;
      const response = await axios.put(
        url,
        { topicId, assignedId },
        {
          headers: {
            Authorization: localStorage.getItem("token"),
          },
        }
      );
      dispatch({ type: UPDATE_READ_STATUS_SUCCESS, payload: response.data });
    } catch (error) {
      console.error(error);
      dispatch({ type: UPDATE_READ_STATUS_FAILURE, payload: error.message });
    }
  };
};

// get flash cards

export const getTopicFlashCard = (topicId, subjectId, setIsLoading) => {
  return async (dispatch) => {
    try {
      setIsLoading(true);
      dispatch({ type: GET_FLASH_CARD_REQUEST });
      const url = `${BASE_URL}/user/subject/get/assigned/flash/cards/${topicId}/${subjectId}/v1`;
      const response = await axios.get(url, {
        headers: {
          Authorization: localStorage.getItem("token"),
        },
      });
      dispatch({ type: GET_FLASH_CARD_SUCCESS, payload: response.data });
    } catch (error) {
      console.error(error);
      dispatch({ type: GET_FLASH_CARD_FAILURE, payload: error.message });
      setIsLoading(false);
    } finally {
      setIsLoading(false);
    }
  };
};

// get Mcq cards
export const getMcqCard = (topicId, subjectId, setIsLoading) => {
  return async (dispatch) => {
    try {
      setIsLoading(true);
      dispatch({ type: GET_MCQ_CARD_REQUEST });
      const url = `${BASE_URL}/user/subject/get/assigned/mcq/cards/${topicId}/${subjectId}/v1`;
      const response = await axios.get(url, {
        headers: {
          Authorization: localStorage.getItem("token"),
        },
      });
      dispatch({ type: GET_MCQ_CARD_SUCCESS, payload: response.data });
    } catch (error) {
      console.error(error);
      dispatch({ type: GET_MCQ_CARD_FAILURE, payload: error.message });
      setIsLoading(false);
    } finally {
      setIsLoading(false);
    }
  };
};

// update opening status for flashCard

export const updateTopicFlashCardStatus = (
  flashCardId,
  topicId,
  assignedId
) => {
  return async (dispatch) => {
    try {
      dispatch({ type: "IS_TOPIC_UPDATE" });
      dispatch({ type: UPDATE_OPEN_STATUS_CARDS_REQUEST });
      const url = `${BASE_URL}/user/subject/update/open/status/${flashCardId}/v1 `;
      const response = await axios.put(
        url,
        { topicId, assignedId },
        {
          headers: {
            Authorization: localStorage.getItem("token"),
          },
        }
      );
      dispatch({
        type: UPDATE_OPEN_STATUS_CARDS_SUCCESS,
        payload: response.data,
      });
    } catch (error) {
      console.error(error);
      dispatch({
        type: UPDATE_OPEN_STATUS_CARDS_FAILURE,
        payload: error.message,
      });
    }
  };
};

// get progress of flash cards

export const getTopicFlashCardProgress = (topicId) => {
  return async (dispatch) => {
    try {
      dispatch({ type: "IS_FLASH_CARD_UPDATE" });
      dispatch({ type: UPDATE_FLASH_CARDS_PROGRESS_REQUEST });
      const url = `${BASE_URL}/flash/cards/get/flash/card/progress/${topicId}/v1 `;
      const response = await axios.put(
        url,
        {},
        {
          headers: {
            Authorization: localStorage.getItem("token"),
          },
        }
      );
      dispatch({
        type: UPDATE_FLASH_CARDS_PROGRESS_SUCCESS,
        payload: response.data,
      });
    } catch (error) {
      console.error(error);
      dispatch({
        type: UPDATE_FLASH_CARDS_PROGRESS_FAILURE,
        payload: error.message,
      });
    }
  };
};

// get Videos

export const getVideos = (topicId, subjectId, setIsLoading) => {
  return async (dispatch) => {
    try {
      setIsLoading(true);
      dispatch({ type: GET_VIDEOS_REQUEST });
      const url = `${BASE_URL}/user/subject/get/assigned/videos/${topicId}/${subjectId}/v1`;
      const response = await axios.get(url, {
        headers: {
          Authorization: localStorage.getItem("token"),
        },
      });
      dispatch({ type: GET_VIDEOS_SUCCESS, payload: response.data });
    } catch (error) {
      console.error(error);
      dispatch({ type: GET_VIDEOS_FAILURE, payload: error.message });
      setIsLoading(false);
    } finally {
      setIsLoading(false);
    }
  };
};

export const updateCompletedStatusForVideos = (
  videoId,
  topicId,
  assignedId
) => {
  return async (dispatch) => {
    try {
      dispatch({ type: "IS_TOPIC_UPDATE" });
      dispatch({ type: UPDATE_COMPLETED_VIDEO_STATUS_REQUEST });
      const url = `${BASE_URL}/user/subject/update/complete/status/${videoId}/v1 `;
      const response = await axios.put(
        url,
        { topicId, assignedId },
        {
          headers: {
            Authorization: localStorage.getItem("token"),
          },
        }
      );
      dispatch({
        type: UPDATE_COMPLETED_VIDEO_STATUS_SUCCESS,
        payload: response.data,
      });
    } catch (error) {
      console.error(error);
      dispatch({
        type: UPDATE_COMPLETED_VIDEO_STATUS_FAILURE,
        payload: error.message,
      });
    }
  };
};
