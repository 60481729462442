import axios from "axios";
import { toast } from "react-toastify";
import { loadStripe } from "@stripe/stripe-js";

const BASE_URL = process.env.REACT_APP_BASE_URL;

export const GET_PLAN_DETAILS_REQUEST = "GET_PLAN_DETAILS_REQUEST";
export const GET_PLAN_DETAILS_SUCCESS = "GET_PLAN_DETAILS_SUCCESS";
export const GET_PLAN_DETAILS_FAILURE = "GET_PLAN_DETAILS_FAILURE";

export const MAKE_PAYMENT_REQUEST = "MAKE_PAYMENT_REQUEST";
export const MAKE_PAYMENT_SUCCESS = "MAKE_PAYMENT_SUCCESS";
export const MAKE_PAYMENT_FAILURE = "MAKE_PAYMENT_FAILURE";

export const SET_PLAN_EXPIRY_REQUEST = "SET_PLAN_EXPIRY_REQUEST";
export const SET_PLAN_EXPIRY_SUCCESS = "SET_PLAN_EXPIRY_SUCCESS";
export const SET_PLAN_EXPIRY_FAILURE = "SET_PLAN_EXPIRY_FAILURE";

export const getPlans = () => {
  return async (dispatch) => {
    try {
      dispatch({ type: GET_PLAN_DETAILS_REQUEST });
      const url = `${BASE_URL}/plan/get/list/v1`;
      const response = await axios.get(url);
      dispatch({ type: GET_PLAN_DETAILS_SUCCESS, payload: response.data });
    } catch (error) {
      console.error(error);
      dispatch({ type: GET_PLAN_DETAILS_FAILURE, payload: error.message });
    }
  };
};

export const setPlanExpiry = (data) => {
  return async (dispatch) => {
    try {
      dispatch({ type: SET_PLAN_EXPIRY_REQUEST });
      const url = `${BASE_URL}/plan/update/subscriber/status/v1`;
      const response = await axios.post(url, data, {
        headers: {
          Authorization: localStorage.getItem("token"),
        },
      });
      dispatch({ type: SET_PLAN_EXPIRY_SUCCESS, payload: response.data });
    } catch (error) {
      console.error(error);
      dispatch({ type: SET_PLAN_EXPIRY_FAILURE, payload: error.message });
    }
  };
};

export const makePayment = (data, navigate) => {
  return async (dispatch) => {
    try {
      dispatch({ type: MAKE_PAYMENT_REQUEST });

      // const urlForPublish = `${BASE_URL}/stripe/get/publish/key/v1`;
      // const getPublishKey = await axios.get(urlForPublish, {
      //   headers: {
      //     Authorization: localStorage.getItem("token"),
      //     "Content-Type": "application/json",
      //   },
      // });
      const stripe = await loadStripe(
        process.env.REACT_APP_STRIPE_PUBLISHER_KEY
      );

      const url = `${BASE_URL}/plan/create/checkout/session/v1`;
      const response = await axios.post(url, data, {
        headers: {
          Authorization: localStorage.getItem("token"),
          "Content-Type": "application/json",
        },
      });

      const result = await stripe.redirectToCheckout({
        sessionId: response.data.id,
      });

      if (result.error) {
        console.log(result.error);
      }

      dispatch({ type: MAKE_PAYMENT_SUCCESS, payload: result });
    } catch (error) {
      console.error(error);
      if (!localStorage.getItem("token")) {
        toast.error("Please Login!!");
        navigate("/login");
      }
      dispatch({ type: MAKE_PAYMENT_FAILURE, payload: error.message });
    }
  };
};
